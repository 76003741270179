import { Link } from "gatsby"
import React, { ReactElement } from "react"

import { Layout, SEO } from "../components"
import * as styles from "./404.module.scss"

const NotFoundPage = (): ReactElement => (
  <Layout>
    <SEO title="404: Not found" lang="ja" />
    <div className={styles.container}>
      <h4 className={styles.title}>お探しのページが見つかりません</h4>
      <p className={styles.message}>
        お探しのページは、以下のいずれかの理由により、見つかりませんでした。
        <br />
        ・URLが間違っている
        <br />
        ・ページのURLが変更された
        <br />
        ・まもなく追加予定のページ
        <br />
        <br />
        トップページに戻って、再度検索してください。
      </p>
      <Link to="/" className={styles.topPageButton}>
        トップページへ
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
